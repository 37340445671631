<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="btn" @click="add">
        <svg-icon className="icon" iconClass="add" />新增
      </div>
      <BaseFilter
        :isActive="isActive"
        :width="516"
        @sendFilter="sendFilter"
        @reset="reset"
      >
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="116px">
            <el-form-item label="供应商代码/名称">
              <el-input
                v-model="filterList.search"
                placeholder="请输入供应商代码/名称/简称"
                style="width: 320px"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务范畴">
              <div class="select">
                <el-select
                  v-model="filterList.scopeValue"
                  multiple
                  placeholder="请选择业务范畴"
                  style="width: 320px"
                >
                  <el-option
                    v-for="item in supplierScopeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="状态">
              <div class="select">
                <el-select
                  v-model="filterList.isActived"
                  placeholder="请选择状态"
                  style="width: 320px"
                >
                  <el-option
                    v-for="item in activeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "使用中" : "未使用" }}
        </template>
        <template slot="supplierScope" slot-scope="row">
          <el-tag
            v-for="(item, $index) in row.scope.supplierScope"
            :key="$index"
          >
            {{ item.scopeDescription }}
          </el-tag>
        </template>
        <template slot="address" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            :tabindex="2000"
            placement="bottom"
          >
            <div slot="content">
              {{
                `${row.scope.countryId}/${row.scope.provinceId}/${row.scope.cityId}`
                  | formatterAddress
              }}{{ row.scope.address }}
            </div>
            <svg-icon iconClass="address" className="icon" />
          </el-tooltip>
        </template>
        <template slot="remark" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            :tabindex="2000"
            :content="row.scope.remark"
            placement="bottom"
          >
            <svg-icon iconClass="remark" className="icon" />
          </el-tooltip>
        </template>
        <template slot="payableAmount" slot-scope="row">
          <template v-if="row.scope.supplierAmount.length > 0">
            <div v-for="(item, $index) in row.scope.supplierAmount" :key="$index">
              {{ item.payableAmount + ' ' + item.currency }}
            </div>
          </template>
        </template>
        <template slot="drafryAmount" slot-scope="row">
          <template v-if="row.scope.supplierAmount.length > 0">
            <div v-for="(item, $index) in row.scope.supplierAmount" :key="$index">
              {{ item.drafryAmount + ' ' + item.currency }}
            </div>
          </template>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-switch
              v-model="row.scope.isActived"
              active-color="#2395F3"
              inactive-color="#CDCDCD"
              @change="
                () => {
                  changeStatus(row.scope);
                }
              "
            >
            </el-switch
            ><span
              class="status"
              :style="{
                color: row.scope.isActived ? '#2395F3' : '#999999',
              }"
            >{{ row.scope.isActived ? "启用" : "停用" }}</span
            >
            <el-button
              type="text"
              @click="updateRow(row.scope)"
            >修改</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <add-supply-dialog
      :dialogVisible="dialogVisible"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></add-supply-dialog>
  </div>
</template>

<script>
let that
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import {
  GetSupplierPageList,
  switchSupplier,
  getSysParams
} from '@/api/system'
import { mapGetters } from 'vuex'
import AddSupplyDialog from './components/AddSupplyDialog.vue'
import { myMixins } from '@/mixins'
export default {
  name: 'Supply',
  components: { BaseTable, BaseFilter, AddSupplyDialog },
  filters: {
    formatterAddress(val) {
      const arr = val.split('/')
      const country =
        that.addressDropOption &&
        that.addressDropOption.find((a) => a.value === parseInt(arr[0], 10))
      const province =
        country &&
        country.children.find((a) => a.value === parseInt(arr[1], 10))
      const city =
        province &&
        province.children.find((a) => a.value === parseInt(arr[2], 10))
      let addressStr = ''
      if (country && province && city) {
        addressStr = `${country.label}${province.label}${city.label}`
      }
      return addressStr
    }
  },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'code',
            label: '代码',
            width: '100px',
            fixed: 'left'
          },
          {
            filed: 'wbName',
            label: '简称',
            width: '120px'
          },
          {
            filed: 'name',
            label: '供应商名称',
            width: '120px'
          },
          // {
          //   filed: 'nameEN',
          //   label: '英文全称',
          //   width: '140px'
          // },
          {
            filed: 'supplierScope',
            label: '业务范畴',
            width: '250px',
            isCustom: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '140',
            isCustom: true
          },
          {
            filed: 'phone',
            label: '联系电话',
            width: '116'
          },
          {
            filed: 'email',
            label: '邮箱',
            width: '170'
          },
          {
            filed: 'address',
            label: '地址',
            width: '60px',
            isCustom: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '60px',
            isCustom: true
          },
          {
            filed: 'payableAmount',
            label: '应付总金额',
            width: '160px',
            isCustom: true
          },
          {
            filed: 'drafryAmount',
            label: '已申付金额',
            width: '160px',
            isCustom: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '150',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '160px'
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: '150',
            formatter: (row) =>
              row.updatedOn ? row.updatedOn.slice(0, row.updatedOn.length - 3) : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: '160px'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '160px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      dialogVisible: false,
      editData: {},
      filterList: {
        search: '',
        isActived: '',
        scopeValue: []
      },
      activeOption: [
        {
          value: true,
          label: '使用中'
        },
        {
          value: false,
          label: '未使用'
        }
      ],
      isActive: false,
      supplierScopeOptions: []
    }
  },
  computed: {
    ...mapGetters(['addressDropOption'])
  },
  beforeCreate: function() {
    that = this
  },
  created() {
    this.getList(this.pagerConfig)
    this.getCommData()
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        GetSupplierPageList(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.total = res.data.total
            this.tableData.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    getCommData() {
      getSysParams({ type: 12 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push({
                label: item.description,
                value: item.dbValue
              })
            })
          this.supplierScopeOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    updateRow(row) {
      this.editData = row
      this.dialogVisible = true
    },
    changeStatus(row) {
      switchSupplier({
        id: row.id
      }).then((res) => {
        res.success && this.$message.success('设置成功')
      })
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    add() {
      this.dialogVisible = true
    },
    ok() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
      this.dialogVisible = false
      this.editData = {}
    },
    cancel() {
      this.dialogVisible = false
      this.editData = {}
    },
    log(row) {
      this.editData = row
      this.drawer = true
    },
    close() {
      this.editData = {}
      this.drawer = false
    },
    formatterFilter() {
      return {
        search:
          this.filterList.search !== ''
            ? this.filterList.search
            : undefined,
        isActived:
          this.filterList.isActived !== ''
            ? this.filterList.isActived
            : undefined,
        scopeValue:
          this.filterList.scopeValue.length > 0
            ? this.filterList.scopeValue
            : undefined
      }
    },
    sendFilter() {
      if (
        this.filterList.search !== '' ||
        this.filterList.isActived !== '' ||
        this.filterList.scopeValue.length > 0
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    reset() {
      this.filterList = {
        search: '',
        isActived: ''
      }
      this.isActive = false
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .filter {
    width: 438px;
  }
}
.select {
  width: 100%;
  &:deep(.el-select) {
    width: 100%;
  }
}
.table-opt:deep(.el-button) {
  padding: 0;
}
.status {
  width: 28px;
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #2395f3;
  line-height: 20px;
  margin-left: 5px;
  margin-right: 31px;
}
.icon {
  font-size: 24px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
