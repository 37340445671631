var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.editData.id ? "修改" : "新增",
        visible: _vm.dialogVisible,
        width: "1160px",
        top: "2vh",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.supplyData, rules: _vm.rules },
            },
            [
              _c("el-row", { staticClass: "row" }, [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("代码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入代码" },
                            model: {
                              value: _vm.supplyData.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "code", $$v)
                              },
                              expression: "supplyData.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-420" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("简称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "wbName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入简称" },
                            model: {
                              value: _vm.supplyData.wbName,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "wbName", $$v)
                              },
                              expression: "supplyData.wbName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-420" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("供应商名称"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入供应商名称" },
                            model: {
                              value: _vm.supplyData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "name", $$v)
                              },
                              expression: "supplyData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", { staticClass: "row" }, [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-420" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("业务范畴"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "supplierScope" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择业务范畴",
                              },
                              model: {
                                value: _vm.supplyData.supplierScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.supplyData, "supplierScope", $$v)
                                },
                                expression: "supplyData.supplierScope",
                              },
                            },
                            _vm._l(_vm.supplierScopeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("联系人"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "contact" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系人" },
                            model: {
                              value: _vm.supplyData.contact,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "contact", $$v)
                              },
                              expression: "supplyData.contact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", { staticClass: "row" }, [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("联系电话"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入联系电话" },
                            model: {
                              value: _vm.supplyData.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "phone", $$v)
                              },
                              expression: "supplyData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [_vm._v("邮箱")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入邮箱" },
                            model: {
                              value: _vm.supplyData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "email", $$v)
                              },
                              expression: "supplyData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("地址"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "addressArr" } },
                        [
                          _c("el-cascader", {
                            attrs: { options: _vm.addressDropOption },
                            model: {
                              value: _vm.supplyData.addressArr,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "addressArr", $$v)
                              },
                              expression: "supplyData.addressArr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("GGT密钥"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "ggtSecretKey" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.supplyData.ggtSecretKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "ggtSecretKey", $$v)
                              },
                              expression: "supplyData.ggtSecretKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _vm._v("FMS密钥"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fmsSecretKey" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.supplyData.fmsSecretKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "fmsSecretKey", $$v)
                              },
                              expression: "supplyData.fmsSecretKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", { staticClass: "row" }, [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "form-item w-860" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("详细地址"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "address" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入详细地址" },
                            model: {
                              value: _vm.supplyData.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "address", $$v)
                              },
                              expression: "supplyData.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item w-200" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("状态"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isActived" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#2395F3",
                              "inactive-color": "#f1f1f1",
                            },
                            model: {
                              value: _vm.supplyData.isActived,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "isActived", $$v)
                              },
                              expression: "supplyData.isActived",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-row", { staticClass: "row" }, [
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-item w-200",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c("label", { staticClass: "lable" }, [_vm._v("备注")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入备注" },
                            model: {
                              value: _vm.supplyData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.supplyData, "remark", $$v)
                              },
                              expression: "supplyData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "add-item-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("银行账号列表")]),
            _c("div", { staticClass: "add-item", on: { click: _vm.add } }, [
              _c("i", { staticClass: "el-icon-circle-plus-outline" }),
              _vm._v(" 添加 "),
            ]),
          ]),
          _c("div", { staticClass: "baseTable" }, [
            _c("ul", [
              _c("li", { staticClass: "header" }, [
                _c("div", { staticClass: "cell cell1" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                  _vm._v("账号名称"),
                ]),
                _c("div", { staticClass: "cell cell2" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                  _vm._v("银行名称 "),
                ]),
                _c("div", { staticClass: "cell cell3" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                  _vm._v("银行账号 "),
                ]),
                _c("div", { staticClass: "cell cell1" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                  _vm._v("币制"),
                ]),
                _c("div", { staticClass: "cell cell1" }, [_vm._v("SwiftCode")]),
                _c("div", { staticClass: "cell cell4" }, [_vm._v("操作")]),
              ]),
            ]),
            _c(
              "ul",
              { staticClass: "tableBody" },
              _vm._l(_vm.bankAccountList, function (item, $index) {
                return _c("li", { key: $index, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: 128,
                          expression: "128",
                        },
                      ],
                      staticClass: "cell cell1",
                    },
                    [_vm._v(_vm._s(item.accountName))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: 221,
                          expression: "221",
                        },
                      ],
                      staticClass: "cell cell2",
                    },
                    [_vm._v(_vm._s(item.bankName))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: 261,
                          expression: "261",
                        },
                      ],
                      staticClass: "cell cell3",
                    },
                    [_vm._v(" " + _vm._s(item.accountNumber) + " ")]
                  ),
                  _c("div", { staticClass: "cell cell1" }, [
                    _vm._v(_vm._s(item.currency)),
                  ]),
                  _c("div", { staticClass: "cell cell1" }, [
                    _vm._v(_vm._s(item.swiftCode)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cell cell4 flex_c_c" },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.isActived ? "启用" : "停用",
                        },
                        model: {
                          value: item.isActived,
                          callback: function ($$v) {
                            _vm.$set(item, "isActived", $$v)
                          },
                          expression: "item.isActived",
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-zoom-in",
                        attrs: { title: "查看文件" },
                        on: {
                          click: function ($event) {
                            return _vm.checkFile(item.systemFileGuid)
                          },
                        },
                      }),
                      _c("img", {
                        staticClass: "download",
                        attrs: {
                          title: "下载文件",
                          src: require("@/assets/images/bill/download.png"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.download(item.systemFileGuid)
                          },
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-delete",
                        attrs: { title: "删除" },
                        on: {
                          click: function ($event) {
                            return _vm.removeBankAccountList($index)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "footers",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "div",
                {
                  staticClass: "ok flex_c_c",
                  on: {
                    click: function ($event) {
                      return _vm.ok("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ]
          ),
          _c("add-bank-dialog", {
            attrs: { dialogVisible: _vm.dialogVisible2 },
            on: { ok: _vm.ok2, cancel: _vm.cancel2 },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }