<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form
        ref="form"
        class="form"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item prop="accountName" label="账号名称">
              <el-input
                v-model="form.accountName"
                placeholder="请输入账号名称"
                style="width: 320px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="bankName" label="银行名称">
              <el-input
                v-model="form.bankName"
                placeholder="请输入银行名称"
                style="width: 320px;"
              ></el-input> </el-form-item
            ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="accountNumber" label="银行账号">
              <el-input
                v-model="form.accountNumber"
                placeholder="请输入银行账号"
                style="width: 320px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="currency" label="币制">
              <el-input
                v-model="form.currency"
                placeholder="请输入币制"
                style="width: 320px;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="swiftCode" label="SwiftCode">
              <el-input
                v-model="form.swiftCode"
                placeholder="请输入SwiftCode"
                style="width: 320px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="isActived" label="账号状态" style="width: 320px;">
              <el-switch
                v-model="form.isActived"
                :active-text="form.isActived ? '启用' : '停用'"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="systemFileId">
              <span slot="label">
                上传文件<span style="color: #FE453D;">(仅支持PDF文件)</span>
              </span>
              <div class="upload">
                <el-upload
                  class="upload-demo"
                  :action="`/backEnd/api/${config.supplyCode}/File/uploadFile?source=8`"
                  :file-list="fileList"
                  :show-file-list="false"
                  :on-success="onSuccess"
                  :on-error="onError"
                  :before-upload="beforeUpload"
                >
                  <el-button class="btn" size="small">
                    <svg-icon iconClass="upload" className="icon" />
                    选择文件上传
                  </el-button>
                </el-upload>
              </div>
              <div v-if="file" class="fileList">
                <div class="file">
                  <div class="qun">
                    <el-progress
                      type="circle"
                      :width="40"
                      :height="40"
                      :stroke-width="4"
                      :percentage="100"
                    >
                    </el-progress>
                  </div>
                  <div class="file-item">
                    <p class="fileName">{{ file.name }}</p>
                    <p class="fileSize">{{ file.size | formatSize }} mb</p>
                  </div>
                </div>
                <div class="opt">
                  <i class="el-icon-delete" @click="deleteFile" />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel('form')">取 消</div>
      <div class="ok" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { isValidNum } from '@/utils/validate'
import config from '@/utils/config'
export default {
  filters: {
    formatSize(size) {
      return (size / 1024 / 1024).toFixed(2)
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateAccountNumber = (rule, value, callback) => {
      if (!isValidNum(value)) {
        callback(new Error('请输入正确的银行账户'))
      } else {
        callback()
      }
    }
    return {
      config,
      form: {
        accountNumber: '',
        accountName: '',
        bankName: '',
        currency: '',
        swiftCode: '',
        isActived: true,
        systemFileId: ''
      },
      rules: {
        accountName: [
          { required: true, message: '请输入账号名称', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '请输入银行名称', trigger: 'blur' }
        ],
        currency: [{ required: true, message: '请输入币制', trigger: 'blur' }],
        accountNumber: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { validator: validateAccountNumber, trigger: 'blur' }
        ]
      },
      fileList: [],
      file: null
    }
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.fileList = []
      this.file = null
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.file) {
            this.$message.warning('请上传文件')
            return false
          }
          this.$emit('ok', this.form)
          this.$refs['form'].resetFields()
          this.fileList = []
          this.file = null
        }
      })
    },
    deleteFile() {
      this.fileList = []
      this.file = null
    },
    onSuccess(res) {
      if (!res.success) {
        this.$message.error(res.errorMessage)
        this.fileList = []
      } else {
        this.form.systemFileId = res.data.fileId
      }
    },
    onError(error) {
      this.$message.error('上传失败')
      console.error(error)
    },
    beforeUpload(file) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (!(suffix === 'pdf')) {
        this.$message.error('仅支持上传PDF格式文件')
        return false
      }
      this.file = file
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 0 auto;
  &:deep(.el-col:nth-child(even)) {
    display: flex;
    justify-content: flex-end;
  }
  .upload {
    width: 320px;
    height: 100px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 160px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    .icon {
      font-size: 24px;
      vertical-align: middle;
    }
  }
  .fileList {
    width: 300px;
    height: 40px;
    padding: 12px 10px;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    .file {
      display: flex;
      flex: 1;
    }
    .opt {
      line-height: 40px;
      font-size: 20px;
      color: #cdcdcd;
      cursor: pointer;
    }
  }
  .file-item {
    text-indent: 10px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
  }
  .fileName {
    color: #666666;
  }
  .fileSize {
    font-size: 12px;
    color: #999999;
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
