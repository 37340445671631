<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="1160px"
    top="2vh"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="supplyData" :rules="rules">
        <el-row class="row">
          <div class="panel">
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>代码</label>
              <el-form-item prop="code">
                <el-input
                  v-model="supplyData.code"
                  placeholder="请输入代码"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-420">
              <label class="lable"><span class="red">* </span>简称</label>
              <el-form-item prop="wbName">
                <el-input
                  v-model="supplyData.wbName"
                  placeholder="请输入简称"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-420">
              <label class="lable"><span class="red">* </span>供应商名称</label>
              <el-form-item prop="name">
                <el-input
                  v-model="supplyData.name"
                  placeholder="请输入供应商名称"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row class="row">
          <div class="panel">
            <!-- <div class="form-item w-420">
              <label class="lable"><span class="red">* </span>英文全称</label>
              <el-form-item prop="nameEN">
                <el-input
                  v-model="supplyData.nameEN"
                  placeholder="请输入英文全称"
                ></el-input>
              </el-form-item>
            </div> -->
            <div class="form-item w-420">
              <label class="lable"><span class="red">* </span>业务范畴</label>
              <el-form-item prop="supplierScope">
                <el-select
                  v-model="supplyData.supplierScope"
                  multiple
                  style="width: 100%"
                  placeholder="请选择业务范畴"
                >
                  <el-option
                    v-for="item in supplierScopeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>联系人</label>
              <el-form-item prop="contact">
                <el-input
                  v-model="supplyData.contact"
                  placeholder="请输入联系人"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row class="row">
          <div class="panel">
            <div class="form-item w-200">
              <label class="lable">联系电话</label>
              <el-form-item prop="phone">
                <el-input
                  v-model="supplyData.phone"
                  placeholder="请输入联系电话"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable">邮箱</label>
              <el-form-item prop="email">
                <el-input
                  v-model="supplyData.email"
                  placeholder="请输入邮箱"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>地址</label>
              <el-form-item prop="addressArr">
                <el-cascader
                  v-model="supplyData.addressArr"
                  :options="addressDropOption"
                ></el-cascader>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable">GGT密钥</label>
              <el-form-item prop="ggtSecretKey">
                <el-input
                  v-model="supplyData.ggtSecretKey"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable">FMS密钥</label>
              <el-form-item prop="fmsSecretKey">
                <el-input
                  v-model="supplyData.fmsSecretKey"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row class="row">
          <div class="panel">
            <div class="form-item w-860">
              <label class="lable"><span class="red">* </span>详细地址</label>
              <el-form-item prop="address">
                <el-input
                  v-model="supplyData.address"
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item w-200">
              <label class="lable"><span class="red">* </span>状态</label>
              <el-form-item prop="isActived">
                <el-switch
                  v-model="supplyData.isActived"
                  active-color="#2395F3"
                  inactive-color="#f1f1f1"
                >
                </el-switch>
              </el-form-item>
            </div>
          </div>
        </el-row>
        <el-row class="row">
          <div class="panel">
            <div class="form-item w-200" style="width: 100%">
              <label class="lable">备注</label>
              <el-form-item prop="remark">
                <el-input
                  v-model="supplyData.remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-row>
      </el-form>
      <div class="add-item-content">
        <div class="title">银行账号列表</div>
        <div class="add-item" @click="add">
          <i class="el-icon-circle-plus-outline" />
          添加
        </div>
      </div>
      <div class="baseTable">
        <ul>
          <li class="header">
            <div class="cell cell1"><span class="red">* </span>账号名称</div>
            <div class="cell cell2">
              <span class="red">* </span>银行名称
            </div>
            <div class="cell cell3">
              <span class="red">* </span>银行账号
            </div>
            <div class="cell cell1"><span class="red">* </span>币制</div>
            <div class="cell cell1">SwiftCode</div>
            <div class="cell cell4">操作</div>
          </li>
        </ul>
        <ul class="tableBody">
          <li v-for="(item, $index) in bankAccountList" :key="$index" class="row">
            <div v-tooltip="128" class="cell cell1">{{ item.accountName }}</div>
            <div v-tooltip="221" class="cell cell2">{{ item.bankName }}</div>
            <div v-tooltip="261" class="cell cell3">
              {{ item.accountNumber }}
            </div>
            <div class="cell cell1">{{ item.currency }}</div>
            <div class="cell cell1">{{ item.swiftCode }}</div>
            <div class="cell cell4 flex_c_c">
              <el-switch
                v-model="item.isActived"
                :active-text="item.isActived ? '启用' : '停用'">
              </el-switch>
              <i
                class="el-icon-zoom-in"
                title="查看文件"
                @click="checkFile(item.systemFileGuid)"
              />
              <img
                class="download"
                title="下载文件"
                src="@/assets/images/bill/download.png"
                @click="download(item.systemFileGuid)"
              >
              <i
                class="el-icon-delete"
                title="删除"
                @click="removeBankAccountList($index)"
              />
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="footers">
        <div class="cancel flex_c_c" @click="cancel">取 消</div>
        <div class="ok flex_c_c" @click="ok('form')">确 定</div>
      </span>
      <add-bank-dialog
        :dialogVisible="dialogVisible2"
        @ok="ok2"
        @cancel="cancel2"
      ></add-bank-dialog>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import AddBankDialog from './AddBankDialog.vue'
import { isValidEmail } from '@/utils/validate'
import { getSysParams, insertSupplier, updateSupplier } from '@/api/system'
import config from '@/utils/config'
export default {
  components: { AddBankDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validateCode = (rule, value, callback) => {
      if (value.length !== 4) {
        callback(new Error('请输入4字代码'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback()
      } else if (!isValidEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    // const validatePhone = (rule, value, callback) => {
    //   if (!isValidPhone(value)) {
    //     callback(new Error('请输入正确的手机号码'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      config,
      supplyData: {
        code: '',
        // nameCH: '',
        // nameEN: '',
        name: '',
        wbName: '',
        contact: '',
        phone: '',
        email: '',
        address: '',
        addressArr: [],
        countryId: 0,
        provinceId: 0,
        cityId: 0,
        remark: '',
        ggtSecretKey: '',
        fmsSecretKey: '',
        isActived: true,
        supplierScope: [],
        bankAccount: []
      },
      bankAccountList: [],
      supplierScopeOptions: [],
      rules: {
        code: [
          { required: true, message: '请输入编码', trigger: 'blur' },
          { validator: validateCode, trigger: 'blur' }
        ],
        wbName: [{ required: true, message: '请输入简称', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
        // nameCH: [
        //   { required: true, message: '请输入中文全称', trigger: 'blur' }
        // ],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'change' }],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        supplierScope: [
          { required: true, message: '请选择业务范畴', trigger: 'blur' }
        ]
      },
      dialogVisible2: false
    }
  },
  computed: {
    ...mapGetters(['addressDropOption'])
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        console.log(this.editData)
        if (this.editData.id) {
          const supplierScope = []
          this.editData.supplierScope.map((item) => {
            supplierScope.push(item.scopValue)
          })
          const addressArr = [
            this.editData.countryId,
            this.editData.provinceId,
            this.editData.cityId
          ]
          this.supplyData = {
            ...this.editData,
            addressArr,
            supplierScope
          }
          this.bankAccountList = [...this.editData.bankAccount]
        } else {
          this.supplyData = {
            code: '',
            // nameCH: '',
            // nameEN: '',
            name: '',
            wbName: '',
            contact: '',
            phone: '',
            email: '',
            address: '',
            addressArr: [],
            countryId: 0,
            provinceId: 0,
            cityId: 0,
            remark: '',
            ggtSecretKey: '',
            fmsSecretKey: '',
            isActived: true,
            supplierScope: [],
            bankAccount: []
          }
          this.bankAccountList = []
        }
      }
    }
  },
  created() {
    this.getCommData()
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.bankAccountList = []
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bankAccountList.length === 0) {
            this.$message.warning('请添加银行账号')
            return
          }
          const supplierScope = []
          this.supplyData.supplierScope.map((item) => {
            const a = this.supplierScopeOptions.find((b) => b.value === item)
            supplierScope.push({
              scopeDescription: a.label,
              scopValue: a.value
            })
          })
          const params = {
            ...this.supplyData,
            countryId: this.supplyData.addressArr[0],
            provinceId: this.supplyData.addressArr[1],
            cityId: this.supplyData.addressArr[2],
            supplierScope,
            bankAccount: [...this.bankAccountList]
          }
          if (!this.editData.id) {
            insertSupplier(params).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          } else {
            updateSupplier(params).then((res) => {
              if (res.success) {
                this.$message.success('修改成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        }
      })
    },
    ok2(data) {
      this.bankAccountList.push({
        ...data
      })
      this.dialogVisible2 = false
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    add() {
      this.dialogVisible2 = true
    },
    removeBankAccountList(index) {
      this.bankAccountList.splice(index, 1)
    },
    getCommData() {
      getSysParams({ type: 12 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push({
                label: item.description,
                value: item.dbValue
              })
            })
          this.supplierScopeOptions = arr
        }
      })
    },
    checkFile(id) {
      window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${id}`)
    },
    download(id) {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/File/downLoadFile?guid=${id}`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .panel {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    .add {
      width: 56px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      line-height: 40px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .w-200 {
    width: 200px;
  }
  .w-420 {
    width: 420px;
  }
  .w-860 {
    width: 860px;
  }
  .red {
    color: #ff7444;
  }
  .add-item-content {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .title {
    align-self: flex-end;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .add-item {
    width: 100px;
    height: 40px;
    background: #35ba83;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .add-item {
    width: 100px;
    height: 40px;
    background: #35ba83;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .baseTable {
    width: 100%;
    height: 240px;
    border: 1px solid #eeeeee;
    margin-top: 10px;
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    box-sizing: border-box;
    ul {
      width: 100%;
    }
    ul li {
      width: 100%;
      height: 40px;
      display: flex;
      text-align: center;
    }
    .header {
      background: #f7f7f7;
      .cell {
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:last-child {
          border-right: 0;
        }
      }
      .cell1 {
        flex: 1;
      }
      .cell2 {
        width: 220px;
      }
      .cell3 {
        width: 260px;
      }
      .cell4 {
        width: 210px;
      }
    }
    .red {
      color: #ff7444;
    }
    .tableBody {
      width: 100%;
      height: 200px;
      overflow: auto;
      .cell {
        width: 14.286%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:last-child {
          border-right: 0;
        }
        .el-icon-delete,
        .el-icon-zoom-in,
        .download {
          color: #2395F3;
          font-size: 16px;
          margin-left: 15px;
          cursor: pointer;
        }
      }
      .cell1 {
        flex: 1;
      }
      .cell2 {
        width: 220px;
      }
      .cell3 {
        width: 260px;
      }
      .cell4 {
        width: 210px;
      }
    }
  }
  .footers {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    & div {
      width: 120px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
    .cancel {
      background: #eeeeee;
      color: #999999;
    }
    .ok {
      background: #2395f3;
      color: #ffffff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
