var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _c(
            "div",
            { staticClass: "btn", on: { click: _vm.add } },
            [
              _c("svg-icon", {
                attrs: { className: "icon", iconClass: "add" },
              }),
              _vm._v("新增 "),
            ],
            1
          ),
          _c(
            "BaseFilter",
            {
              attrs: { isActive: _vm.isActive, width: 516 },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "116px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "供应商代码/名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              placeholder: "请输入供应商代码/名称/简称",
                            },
                            model: {
                              value: _vm.filterList.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "search", $$v)
                              },
                              expression: "filterList.search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "业务范畴" } }, [
                        _c(
                          "div",
                          { staticClass: "select" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择业务范畴",
                                },
                                model: {
                                  value: _vm.filterList.scopeValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "scopeValue", $$v)
                                  },
                                  expression: "filterList.scopeValue",
                                },
                              },
                              _vm._l(_vm.supplierScopeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "状态" } }, [
                        _c(
                          "div",
                          { staticClass: "select" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: { placeholder: "请选择状态" },
                                model: {
                                  value: _vm.filterList.isActived,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "isActived", $$v)
                                  },
                                  expression: "filterList.isActived",
                                },
                              },
                              _vm._l(_vm.activeOption, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              "background-color": row.scope.isActived
                                ? " #35BA83"
                                : "#FE453D",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.scope.isActived ? "使用中" : "未使用"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "supplierScope",
                      fn: function (row) {
                        return _vm._l(
                          row.scope.supplierScope,
                          function (item, $index) {
                            return _c("el-tag", { key: $index }, [
                              _vm._v(" " + _vm._s(item.scopeDescription) + " "),
                            ])
                          }
                        )
                      },
                    },
                    {
                      key: "address",
                      fn: function (row) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                tabindex: 2000,
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatterAddress")(
                                          `${row.scope.countryId}/${row.scope.provinceId}/${row.scope.cityId}`
                                        )
                                      ) +
                                      _vm._s(row.scope.address) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("svg-icon", {
                                attrs: {
                                  iconClass: "address",
                                  className: "icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "remark",
                      fn: function (row) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                tabindex: 2000,
                                content: row.scope.remark,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  iconClass: "remark",
                                  className: "icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "payableAmount",
                      fn: function (row) {
                        return [
                          row.scope.supplierAmount.length > 0
                            ? _vm._l(
                                row.scope.supplierAmount,
                                function (item, $index) {
                                  return _c("div", { key: $index }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.payableAmount +
                                            " " +
                                            item.currency
                                        ) +
                                        " "
                                    ),
                                  ])
                                }
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "drafryAmount",
                      fn: function (row) {
                        return [
                          row.scope.supplierAmount.length > 0
                            ? _vm._l(
                                row.scope.supplierAmount,
                                function (item, $index) {
                                  return _c("div", { key: $index }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.drafryAmount +
                                            " " +
                                            item.currency
                                        ) +
                                        " "
                                    ),
                                  ])
                                }
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#2395F3",
                                  "inactive-color": "#CDCDCD",
                                },
                                on: {
                                  change: () => {
                                    _vm.changeStatus(row.scope)
                                  },
                                },
                                model: {
                                  value: row.scope.isActived,
                                  callback: function ($$v) {
                                    _vm.$set(row.scope, "isActived", $$v)
                                  },
                                  expression: "row.scope.isActived",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "status",
                                  style: {
                                    color: row.scope.isActived
                                      ? "#2395F3"
                                      : "#999999",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.scope.isActived ? "启用" : "停用"
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateRow(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1497200915
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("add-supply-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible, editData: _vm.editData },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }